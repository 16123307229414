import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2245e493"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  id: "rank-card",
  class: "d-flex align-items-center"
}
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "ms-3" }
const _hoisted_4 = { class: "text-primary font-weight-bold font-raleway mb-0" }
const _hoisted_5 = { class: "font-weight-bold font-raleway mb-0" }
const _hoisted_6 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_indicator = _resolveComponent("loading-indicator")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.rank)
      ? (_openBlock(), _createBlock(_component_loading_indicator, {
          key: 0,
          class: "col"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.rank && _ctx.rank.imageUrl)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.rank.imageUrl,
                class: "img-fluid sticker-sizing",
                alt: `${_ctx.rank.name} Image`
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.rank?.name), 1),
            _createElementVNode("p", _hoisted_5, " Level " + _toDisplayString(_ctx.rank?.level), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.rank?.pointsToNextLevel) + " points to next level ", 1)
          ])
        ]))
  ]))
}