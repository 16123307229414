
import { defineComponent, PropType } from "vue";
import { RankModel } from "@/models";

export default defineComponent({
    props: {
        rank: {
            type: Object as PropType<RankModel>,
            required: true
        }
    }
});
